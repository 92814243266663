import React from 'react'
import { DDCFooterCopywrite } from 'composable/components/ddc-footer-copywrite'

interface DDCFooterProps {
  data: {
    copyrighttext: { en_US?: string } | string
  }
}

/*
  Cavaet from CoFe
  - On prod the data object is coming as string, but on dev it is coming localized
  - Also, this project is only setup for one Locale for now: en_US
*/

const DDCFooterCopyriteTastic: React.FC<DDCFooterProps> = ({ data }) => {
  const stringToRender =
    typeof data?.copyrighttext === 'string' ? data?.copyrighttext : data?.copyrighttext?.en_US ?? ''

  return <DDCFooterCopywrite copyrightText={stringToRender} />
}

export default DDCFooterCopyriteTastic
